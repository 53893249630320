@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy');
  src: local('Gilroy-Bold');
  src: url('../fonts/Gilroy-Bold/Gilroy-Bold.woff') format('woff'),
  url('../fonts/Gilroy-Bold/Gilroy-Bold.woff') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy');
  src: local('Gilroy-Semibold');
  src: url('../fonts/Gilroy-Semibold/Gilroy-Semibold.woff') format('woff'),
  url('../fonts/Gilroy-Semibold/Gilroy-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy');
  src: local('Gilroy-Medium');
  src: url('../fonts/Gilroy-Medium/Gilroy-Medium.woff') format('woff'),
  url('../fonts/Gilroy-Medium/Gilroy-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}