/*------------------------
    Footer Styles  
-------------------------*/
.axil-footer-area {
    background-color: var(--color-header);
}

.footer-top {
    padding: 80px 0 40px;
    position: relative;

    @media #{$sm-layout} {
        padding: 60px 0 20px;
    }

    @media (min-width: 992px) {
        .footer-column {
            display: flex;
        }
    }

    .col-sm-6:nth-child(2) {
        margin-bottom: 0;
    }

    &.separator-top {
        &::after {
            position: absolute;
            top: 0;
            width: 1290px;
            height: 2px;
            background-color: #F6F7FB;
            content: "";
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 100px;
        }
    }
}


/*----------------------
    Footer Widget  
----------------------*/

.axil-footer-widget {
    &.logo-wrap {
        margin-bottom: 24px;

        @media (min-width: 992px) {
            margin-bottom: 0;
        }
    }

    .widget-title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.025em;
        margin-bottom: 20px;
        color: var(--color-white);

        @media (min-width: 992px) {
            font-size: 16px;
        }
    }
    .inner {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            &.two-col-list {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                @media (max-width: 992px) {
                    flex-direction: column;
                }
                .left-block,
                .right-block {
                    margin: 0;
                }
                .right-block {
                    margin-left: 30px;
                    @media (max-width: 992px) {
                        margin-left: 0;

                    }
                }
            }
            >li {
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        ul {
            list-style: none;
            li {
                margin-top: 12px;
                margin-bottom: 20px;

                @media (min-width: 992px) {
                    margin-bottom: 12px;
                }

                a {
                    color: var(--color-white);
                    font-weight: 500;
                    font-size: 14px;
                    transition: 0.3s;
                    position: relative;

                    @media (min-width: 992px) {
                        font-size: 16px;
                    }

                    &:after {
                        content: "";
                        height: 2px;
                        width: 0;
                        background-color: var(--color-black);
                        position: absolute;
                        bottom: -2px;
                        right: 0;
                        opacity: 0;
                        transition: 0.5s;
                    }
                    &:hover {
                        color: var(--color-btn);
                        //&:after {
                        //    width: 100%;
                        //    opacity: 1;
                        //    left: 0;
                        //}
                    }
                }
            }
        }
    }
    .logo {
        display: block;
        max-width: 266px;
        width: 100%;
        margin: 0 auto 1rem;

        img {
            height: 100%;
            width: 100%;
        }
        @media (min-width: 992px) {
            max-width: 255px;
            margin: 0 0 54px;
        }
    }
    .social-medias {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 992px) {
            justify-content: flex-start;
        }

        a {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;

            @media (min-width: 992px) {
                width: 48px;
                height: 48px;
            }

            &:last-child {
                margin-right: 0;
            }

            img {
                width: 50%;
                height: 50%;
            }
        }
    }
    .support-list-item {
        margin-bottom: 0;
        li {
            padding-left: 26px;
            position: relative;
            a {
                position: initial!important;
                font-weight: 400 !important;
                &:after {
                    display: none;
                }
            }
            i {
                padding-right: 5px;
                position: absolute;
                top: 5px;
                left: 0;
            }
        }
    }
}


/*-----------------------
    Copyright Area  
------------------------*/

.copyright-default {
    padding: 24px 0 24px;

    .copyright-left {
        justify-content: center;
    }

    @media (min-width: 992px) {
        padding: 0 0 40px;

        .copyright-left {
            justify-content: flex-start;
        }
    }

    .quick-link {
        @extend %liststyle;
        display: flex;
        margin: -15px;
        li {
            margin: 10px 0;
            padding: 0 30px;
            position: relative;
            color: var(--color-copyright);
            font-weight: 600;
            font-size: 12px;
            &::after {
                position: absolute;
                content: "";
                background: var(--color-lightest);
                width: 5px;
                height: 5px;
                border-radius: 100%;
                right: -3px;
                top: 50%;
                transform: translateY(-50%);
                @media #{$large-mobile} {
                    display: none;
                }
            }
            a {
                color: var(--color-copyright);
                font-weight: 600;
                font-size: 12px;
                letter-spacing: -0.025em;
                transition: 0.5s;
                position: relative;
                &:after {
                    content: "";
                    height: 2px;
                    width: 0;
                    position: absolute;
                    bottom: -2px;
                    right: 0;
                    opacity: 0;
                    transition: 0.5s;
                }
                &:hover {
                    color: var(--color-white);
                    &:after {
                        width: 100%;
                        opacity: 1;
                        left: 0;
                    }
                }
            }
        }
        &.payment-icons-bottom {
            margin: -15px -10px;
            li {
                padding: 15px 10px;
                &::after {
                    display: none;
                }
            }
        }
    }
    &.separator-top {
        position: relative;
        &::after {
            position: absolute;
            content: "";
            background-color: #F6F7FB;
            border-radius: 100px;
            height: 2px;
            width: 1290px;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 0;
        }
    }
    .copyright-right {
        span {
            &.card-text {
                color: var(--color-body);
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                margin: 10px 20px;
                letter-spacing: -0.025em;
                @media #{$large-mobile} {
                    margin-left: 0;   
                }
            }
        }
    }
    .copyright-left {
       @media #{$smlg-device} {
            text-align: center;  
       }
       @media #{$large-mobile} {
            flex-direction: column;
           padding-left: 1.5rem;
       }

        ul+ul {
            margin-left: 15px;
            @media #{$large-mobile} {
                margin-left: -15px;   
            }
            li {
                &::after {
                    display: none;
                }
            }
        }
    }
}


// Footer Dark Styles
.footer-dark {
    background-color: var(--color-heading);
    .axil-footer-widget {
        p {
            color: #acacac;
        }
    }
    .social-share {
        a {
            color: #acacac;
            &:hover {
                color: var(--color-white);
            }
        }
    }
    .axil-footer-widget {
        .widget-title {
            color: #c8c8c8;
        }
        .inner {
            ul {
                li {
                    a {
                        color: #acacac;
                        &:after {
                            background-color: #acacac;
                        }
                    }
                }
            }
        }
    }
    .copyright-default {
        &.separator-top {
            &:after {
                background-color: #454545;
            }
        }
        .quick-link {
            li {
                color: #acacac;
                &:after {
                    background: #acacac;
                }
                a {
                    color: #acacac;
                    &:after {
                        background-color: #acacac;
                    }
                }
            }
        }
        .copyright-right {
            span.card-text {
                color: #acacac;
            }
        }
    }
}


// Footer Style 2
.footer-style-2 {
    .footer-top {
        padding: 33px 0 0;

        @media #{$large-mobile} {
            padding: 34px 0 0;
        }

        &.separator-top {
            &:after {
                background-color: #F6F7FB;
                height: 2px;
            }
        }
    }
    .axil-footer-widget {
        .widget-title {
            font-weight: 600;
            @media #{$large-mobile} {
                margin-top: 1rem;
            }
        }
        .inner {
            .download-btn-group {
                display: flex;
                align-items: center;
                margin-top: 15px;
                .qr-code {
                    margin-right: 20px;
                    img {
                        @media #{$lg-layout} {
                            height: 80px;
                        }
                    }
                }
                .app-link {
                    flex: 1;
                    a {
                        margin-bottom: 15px;
                        display: block;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .copyright-default {
        .quick-link {
            li {
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
            &.payment-icons-bottom {
                li {
                    padding: 10px;
                    img {
                       height: 20px;
                    }
                }
            }
        }
        .social-share {
            @media #{$smlg-device} {
                justify-content: center;
                margin-top: 0;
                margin-bottom: 0;
            }
            a {
                font-size: 16px;
                color: var(--color-body);
                line-height: normal;
                &:after {
                    height: 35px;
                    width: 35px;
                }
                &:hover {
                    color: var(--color-white);
                }
            }
        }
    }
}

// Footer Style 3
.footer-style-3 {
    .footer-top {
        padding: 35px 0 0 0;
    }
    .footer-widget-warp {
        border-bottom: 1px solid rgba(119, 119, 119, .4);
        padding-top: 30px;
        &:last-child {
            padding-bottom: 30px;
        }
    }
    .footer-middle {
        padding: 28px 0;
    }
    .payment-method {
        display: flex;
        align-items: center;
        .title {
            color: var(--color-white);
            margin-bottom: 0;
            padding-right: 24px;
            text-align: right;
            font-size: 14px;
            line-height: 1.5;
            min-width: 90px;
        }
        ul {
            border-left: 1px solid rgba(119, 119, 119, .4);
            margin-bottom: 0;
            padding-left: 18px;
            list-style: none;
            margin: -6px;
            li {
                text-align: center;
                display: inline-block;
                height: 40px;
                width: 40px;
                line-height: 40px;
                border-radius: 50%;
                background-color: var(--color-white);
                margin: 6px;
                img {
                    max-width: 28px;
                }
            }
        }
    }
    .footer-social-link {
        @media (max-width: 991px) {
            margin-top: 20px;
        }
        ul {
            list-style: none;
            border-left-color: rgba(51, 120, 240, .4);
            li {
                background-color: transparent;
                a {
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    background-color: var(--color-primary);
                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    font-size: 18px;
                    color: var(--color-white);
                    &:hover {
                        background-color: var(--color-secondary);
                    }
                }
            }
        }
    }
    .copyright-area {
        border-top: 1px solid rgba(119, 119, 119, .1);
        .quick-link {
            li {
                a {
                    font-weight: 400;
                }
            }
        }
    }
}

.axil-footer-widget {
    &.widget-flex {
        display: flex;
        margin-bottom: 30px;
        .widget-title {
            border-right: 1px solid rgba(119, 119, 119, .4);
            padding-right: 22px;
            margin-right: 22px;
            font-size: 14px;
            margin-bottom: 0;
            min-width: 90px;
            font-weight: 700;
            text-align: right;
        }
        .inner {
            flex: 1;
            ul {
                margin-bottom: 0;
                li {
                    margin: 10px 0;
                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        font-size: 14px;
                        color: #D6D6D6;
                        font-family: var(--font-secondary);
                    }
                }
            }
        }
    }
    &.footer-widget-newsletter {
        padding-right: 50px;
        .input-group {
            input {
                background-color: #49495F;
                height: 46px;
                border-radius: 8px 0 0 8px;
                padding: 0 20px;
                border: none;
                color: #D6D6D6;
                &:focus {
                    background-color: #49495F;
                    box-shadow: none;
                    color: #D6D6D6;
                }
                &::placeholder {
                    color: #D6D6D6;
                    /* Firefox */
                    opacity: 1;
                }
                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #D6D6D6;
                }
                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #D6D6D6;
                }
            }
            button {
                width: auto;
                background-color: var(--color-primary);
                font-size: 14px;
                font-weight: 700;
                border-radius: 0 8px 8px 0;
                color: var(--color-white);
                padding: 0 24px;
                &:hover {
                    background-color: var(--color-secondary);
                }
            }
        }
        .widget-title {
            color: var(--color-white);
            font-size: 24px;
            margin-bottom: 8px;
        }
        p {
            color: #D6D6D6;
            font-size: 14px;
            margin-bottom: 16px;
            span {
                color: #FE497C;
            }
        }
    }
}


/*-----------------------
   Offer Popup Modal Area  
------------------------*/
.offer-popup-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(.8);
    z-index: 101;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    .offer-popup-wrap {
        background-color: var(--color-white);
        border-radius: 6px;
        padding: 50px;
        width: 730px;
        height: 450px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        //background-image: url('../../assets/images/others/popup-bg.png');
        background-repeat: no-repeat;
        background-position: center right;
        @media only screen and (max-width: 767px) {
            width: 100%;   
            padding: 40px 30px;
            max-height: 400px;
            background-image: none;
        }
        @media #{$small-mobile} {
            padding: 30px 20px;
            max-height: 370px;
        }
        .popup-close {
            height: 40px;
            width: 40px;
            font-size: 18px;
            color: var(--color-white);
            background-color: var(--color-primary);
            border-radius: 50%;
            position: absolute;
            top: -30px;
            right: -30px;
            &:hover {
                background-color: var(--color-secondary);
            }
            @media only screen and (max-width: 767px){
                height: 30px;
                width: 30px;
                font-size: 12px;     
                top: -30px;
                right: -15px;
            }
            @media #{$small-mobile} {
                top: -20px;
                right: -10px;
            }
        }
        .card-body {
            position: relative;
            padding: 0;
            display: flex;
            align-items: center;
            .section-title-wrapper {
                margin-bottom: 0;
                @media only screen and (max-width: 767px) {
                    padding-right: 0;
                }
                .title {
                    font-size: 40px;
                    line-height: 1.2;
                    @media only screen and (max-width: 767px) {
                        font-size: 28px;
                    }
                }
            }
            .countdown {
                margin-bottom: 38px;
                .countdown-section {
                    background-color: var(--color-lighter);
                }
                
            }
            .axil-btn {
                i {
                    margin-right: 0;
                    margin-left: 8px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
}
.offer-popup-modal.open {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition: all .3s cubic-bezier(0.29, 1.39, 0.86, 1.15);
}