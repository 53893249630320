/*-------------------------
    Main Menu Nav  
--------------------------*/
.mainmenu-nav {
    @media only screen and (max-width: 991px) {
        display: none;   
    }
}
.mainmenu {
    @extend %liststyle;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -24px;
    @media #{$smlg-device} {
        margin: 0 -15px;
    }
    >li {
        margin: 0 24px;
        @media #{$smlg-device} {
            margin: 0 15px;
        }
        >a,
        > button {
            color: var(--color-white);
            font-weight: 600;
            font-size: 14px;
            font-family: var(--font-primary);
            line-height: 72px;
            height: 72px;
            display: block;
            position: relative;
            background-color: transparent;
            border: none;
            outline: none;
            transition: var(--transition);
            //&::before {
            //    content: "";
            //    height: 2px;
            //    width: 0;
            //    background-color: var(--color-btn);
            //    position: absolute;
            //    bottom: 29px;
            //    left: 0;
            //    opacity: 0;
            //    transition: 0.5s;
            //}
            &:hover {
                color: var(--color-btn);
                &::before {
                    opacity: 1;
                    width: 100%;
                }
            }
            &.active {
                color: var(--color-black);
                &:before {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }
    >.menu-item-has-children .mob {
        span {
            display: none;
        }
    }
    >.menu-item-has-children {
        position: relative;
        margin-left: 0 !important;
        >a,
        > button  {
            position: relative;
            background-color: transparent;
            border: none;
            outline: none;
            span {
                position: absolute;
                top: 1px;
                right: -14px;
                path {
                    fill: #c6c6c6;
                }
            }
            //&::after {
            //    content: "";
            //    font-family: var(--font-awesome);
            //    font-weight: 400;
            //    color: #c6c6c6;
            //    font-size: 16px;
            //    position: absolute;
            //    top: 1px;
            //    right: -14px;
            //}
        }
        &.menu-item-open {
            >a,
            > button {
                &:before {
                    width: 100%;
                    opacity: 1;
                }
            }
        }

        .mobile-header-btn {
            color: var(--color-body);
            line-height: var(--line-height-b2) !important;
            height: auto !important;
            width: 100%;
            padding: 5px 0;
            display: inline-block;
            text-align: left;
            position: relative;
            span {
                position: absolute;
                top: 1px;
                right: 16px;
                path {
                    fill: #c6c6c6;
                }
                z-index: -1;
            }
        }

        .axil-submenu {
            position: absolute;
            top: 100%;
            left: 0;
            background: #ffffff;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            min-width: 250px;
            padding: 15px 10px;
            border-radius: 4px;
            box-shadow: var(--shadow-primary);
            transition: all 0.3s ease-in-out;
            list-style: none;
            pointer-events: none;
            li {
                margin: 0;
                a,
                button {
                    position: relative;
                    font-size: 15px;
                    text-transform: capitalize;
                    color: var(--color-heading);
                    font-weight: 500;
                    padding: 5px 15px;
                    border-radius: 4px;
                    display: block;
                    transition: all 0.3s ease-in-out;
                    z-index: 1;
                    overflow: hidden;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    text-align: left;
                    &:hover {
                        color: var(--color-btn);
                        // background: var(--color-btn);
                    }
                    &.active {
                        color: var(--color-btn);
                        &:hover {
                            color: var(--color-btn);
                        }
                    }
                }
            }
        }

        .menu-item-children {
            & > a,
            & > button {
                span {
                    position: absolute;
                    top: 1px;
                    right: 0;
                    path {
                        fill: #c6c6c6;
                    }
                }
                //&::after {
                //    content: "\f107";
                //    font-family: var(--font-awesome);
                //    font-weight: 400;
                //    color: #c6c6c6;
                //    font-size: 16px;
                //    position: absolute;
                //    top: 1px;
                //    right: 0;
                //    transform: rotate(-90deg);
                //}
            }

            .children-menu-item {
                position: absolute;
                top: 0;
                right: -80%;
                background: #ffffff;
                display: flex;
                flex-direction: column;
                z-index: -1;
                opacity: 0;
                visibility: hidden;
                min-width: 250px;
                max-width: 250px;
                min-height: 100%;
                padding: 15px 10px;
                border-radius: 4px;
                box-shadow: var(--shadow-primary);
                transition: all 0.3s ease-in-out;
                list-style: none;
                pointer-events: none;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: -50px;
                }

                li {
                    margin: 0;

                    a,
                    button {
                        position: relative;
                        font-size: 15px;
                        text-transform: capitalize;
                        color: var(--color-heading);
                        background-color: transparent;
                        border: none;
                        outline: none;
                        text-align: left;
                        font-weight: 500;
                        padding: 8px 15px;
                        border-radius: 4px;
                        display: block;
                        transition: all 0.3s ease-in-out;
                        z-index: 1;
                        overflow: hidden;
                        &:hover {
                            color: var(--color-secondary);
                            // background: var(--color-secondary);
                        }
                        &.active {
                            color: var(--color-secondary);
                            &:hover {
                                color: var(--color-secondary);
                            }
                        }
                    }
                }
            }

            &:hover {
                .children-menu-item {
                    right: -105%;
                    opacity: 1;
                    visibility: visible;
                    z-index: 9;
                    pointer-events: all;
                }
            }
        }

        &:hover {
            .axil-submenu {
                top: 90%;
                opacity: 1;
                visibility: visible;
                z-index: 9;
                pointer-events: all;
            }
        }
    }
}

