/*-------------------------
    Breadcrumb Styles  
--------------------------*/
.axil-breadcrumb-area {
    position: relative;
    background-color: #f8f8f8;
    padding: 40px 0 45px;
    .inner {
        .title {
            font-size: 40px;
            margin-bottom: 0;
            @media #{$md-layout} {
               font-size: 34px;
            }
            @media #{$sm-layout} {
               font-size: 28px;
            }
            @media #{$large-mobile} {
               font-size: 24px;
            }
        }
        .bradcrumb-thumb {
            text-align: right;
            position: relative;
            z-index: 1;
            @media #{$sm-layout} {
                display: none;
            }
            &::after {
                content: "";
                height: 110px;
                width: 110px;
                background-color: var(--color-white);
                border-radius: 50%;
                position: absolute;
                top: -10px;
                right: 60px;
                z-index: -1;
            }
        }
    }
}
.axil-breadcrumb {
    display: flex;
    padding: 0;
    margin: 0 0 15px;
    list-style: none;
    align-items: center;
    li {
        margin-top: 0;
        margin-bottom: 0;
        font-size: var(--font-size-b1);
        line-height: var(--line-height-b1);
        font-weight: 500;
        a {
            color: #999999;
            display: block;
        }
        &.axil-breadcrumb-item {
            &.active {
                color: var(--color-primary);
                background-color: transparent;
            }
        }
        &.separator {
            height: 11px;
            width: 2px;
            background-color: #e5e5e5;
            margin: 0 8px;
        }
    }
}

    











