/*----------------------
 Typography
-----------------------*/

 * {
	box-sizing: border-box;
}

html {
	overflow: hidden;
	overflow-y: auto;
	margin: 0;
	padding: 0;
	font-size: 10px;
}
@media only screen and (min-width: 992px) {
	body.overflow-visible {
		overflow: visible;
		overflow-y: visible;
		
	}
}

body {
	font-size: var(--font-size-b1);;
	line-height: var(--line-height-b1);;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-primary);
	color: var(--color-body);
	font-weight: var(--p-regular);
	overflow: hidden;
	//overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
	margin: 0;
	margin-bottom: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	word-break: break-word;
	font-family: var(--font-secondary);
	line-height: 1.3;
	color: var(--color-heading);
}

h1,
.h1 {
	font-size: var(--h1);
}

h2,
.h2 {
	font-size: var(--h2);
}

h3,
.h3 {
	font-size: var(--h3);
}

h4,
.h4 {
	font-size: var(--h4);
}

h5,
.h5 {
	font-size: var(--h5);
}

h6,
.h6 {
	font-size: var(--h6);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	a {
		color: inherit;
	}
}

@media #{$md-layout} {
	h1,
	.h1 {
		font-size: 40px;
	}
	h2,
	.h2 {
		font-size: 30px;
	}

	h3,
	.h3 {
		font-size: 26px;
	}

	h4,
	.h4 {
		font-size: 22px;
	}
}


@media #{$sm-layout} {
	h1,
	.h1 {
		font-size: 34px;
	}
	h2,
	.h2 {
		font-size: 26px;
	}

	h3,
	.h3 {
		font-size: 24px;
	}

	h4,
	.h4 {
		font-size: 20px;
	}
}


h1,
.h1,
h2,
.h2,
h3,
.h3 {
	font-weight: var(--s-bold);
}

h4,
.h4,
h5,
.h5 {
	font-weight: var(--s-bold);
}

h6,
.h6 {
	font-weight: var(--s-medium);
}


h1,
h2,
h3,
h4,
h5,
h6 {
	&.b1 {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
	}
	
	&.b2 {
		font-size: var(--font-size-b2);
		line-height: var(--line-height-b2);
	}

	&.b3 {
		font-size: var(--font-size-b3);
		line-height: var(--line-height-b3);
	}
}

p {
	font-size: var(--font-size-b1);
	line-height: var(--line-height-b1);
	font-weight: var(--p-regular);
	color: var(--color-body);
	margin: 0 0 30px;
	&.has-large-font-size {
		line-height: 1.5;
		font-size: 36px;
	}
	&.has-medium-font-size {
		font-size: 24px;
		line-height: 36px;
	}

	&.has-small-font-size {
		font-size: 13px;
	}

	&.has-very-light-gray-color {
		color: var(--color-white);
	}

	&.has-background {
		padding: 20px 30px;
	}

	&.b1 {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
	}
	
	&.b2 {
		font-size: var(--font-size-b2);
		line-height: var(--line-height-b2);
	}

	&.b3 {
		font-size: var(--font-size-b3);
		line-height: var(--line-height-b3);
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.b1 {
	font-size: var(--font-size-b1);
	line-height: var(--line-height-b1);
}

.b2 {
	font-size: var(--font-size-b2);
	line-height: var(--line-height-b2);
}

.b3 {
	font-size: var(--font-size-b3);
	line-height: var(--line-height-b3);
}


table {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 0 0 20px;
	width: 100%;
}

table a,
table a:link,
table a:visited {
	text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
	color: var(--heading-color);
}

var {
	font-family: "Courier 10 Pitch", Courier, monospace;
}

/*---------------------------
	List Style 
---------------------------*/
ul,
ol {
	padding-left: 18px;
}

ul {
    list-style: square;
	margin-bottom: 30px;
	padding-left: 20px;
	&.liststyle {
		&.bullet {
			li {
				font-size: 18px;
				line-height: 30px;
				color: var(--color-body);
				position: relative;
				padding-left: 30px;

				@media #{$sm-layout} {
					padding-left: 19px;
				}

				&::before {
					position: absolute;
					content: "";
					width: 6px;
					height: 6px;
					border-radius: 100%;
					background: var(--color-body);
					left: 0;
					top: 10px;
				}

				&+li {
					margin-top: 8px;
				}
			}
		}
	}
	li {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
		margin-top: 8px;
		margin-bottom: 8px;
		color: var(--color-body);
		font-family: var(--font-secondary);
		a {
			text-decoration: none;
			color: var(--color-heading);
			@extend %transition;

			&:hover {
				color: var(--color-primary);
			}
		}
		&::marker {
			color: var(--color-body);
		}
	}
	ul {
		margin-bottom: 0;
	}
}

ol {
	margin-bottom: 30px;
	li {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
		color: var(--color-body);
		margin-top: 8px;
		margin-bottom: 8px;

		a {
			color: var(--heading-color);
			@extend %transition;
			text-decoration: none;

			&:hover {
				color: var(--color-primary);
			}
		}
	}
	ul {
		padding-left: 30px;
	}
}