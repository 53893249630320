.cookie-popup {
  width: 100%;
  max-width: initial;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -100%;
  padding: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease;
  text-align: center;
  z-index: 5;

  .cookie-popup-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 767px) {
      flex-direction: row;
    }
  }

  @media (min-width: 767px) {
    padding: 15px 0;
  }

  &.visible-popup {
    bottom: 0;
  }

  h3 {
    max-width: 647px;
    width: 100%;
    font-size: 15px;
    color: #FFF;
    margin: 0 0 10px;
    text-align: left;

    @media (min-width: 767px) {
      margin: 0 24px 0 0;
    }
  }

  .cookie-mobile-btn-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .cookie-link, .accept-cookie {
    width: 100%;
    padding: 10px 0;
    max-width: 160px;
    font-size: 13px;
    font-weight: 700;
    line-height: normal;
    color: #FFF;
    margin: 0;

    @media (min-width: 1025px) {
      padding: 12px 0;
      max-width: 181px;
      font-size: 13px;
    }
  }

  .accept-cookie {
    text-align: center;
    border-radius: 4px;
    background: #053D7E;
  }

  .cookie-link {
    background: transparent;
    text-decoration: underline;
  }
}