/*-------------------------
Slider Style
--------------------------*/
.main-slider-content {
    .subtitle {
        font-size: 14px;
        font-weight: var(--s-bold);
        line-height: 1;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        color: var(--color-secondary);
        i {
            height: 24px;
            width: 24px;
            line-height: 24px;
            border-radius: 50%;
            font-size: 12px;
            text-align: center;
            margin-right: 10px;
            background-color: var(--color-secondary);
            color: var(--color-white);
        }
    }
    .title {
        letter-spacing: -0.03em;
        margin-bottom: 50px;
        font-size: 60px;
        line-height: 1.2;
        @media #{$smlg-device} {
            font-size: 50px;
        }
        @media #{$md-layout} {
            font-size: 44px;
        }
        @media #{$sm-layout} {
            font-size: 30px;
        }
    }
    .slide-action {
        display: flex;
        align-items: center;
        @media #{$smlg-device} {
            display: block;
        }
    }
    .item-rating {
        display: flex;
        align-items: center;
        flex: 1;
        margin-left: 30px;
        position: relative;
        top: 2px;
        @media #{$smlg-device} {
            margin-left: 0;
            margin-top: 30px;
        }
        .thumb {
            line-height: 1;
            ul {
                padding-left: 26px;
                margin: 0;
                li {
                    @extend %liststyle;
                    display: inline-block;
                    margin-left: -26px;
                    img {
                        border: 2px solid #f9f3f0;
                        border-radius: 50%;
                    }
                }
            }
        }
        .content {
            flex: 1;
            line-height: 1;
            margin-left: 15px;
            .rating-icon {
                display: block;
                font-size: 12px;
                color: #ffa800;
                margin-bottom: 5px;
            }
            .review-text {
                font-size: 12px;
                span {
                    color: var(--color-heading);
                    font-weight: 700;
                }
            }
        }
    }
    .search-form {
        width: 30%;
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
        @media only screen and (max-width: 768px) {
            width: 60%;
        }
        @media only screen and (max-width: 640px) {
            width: 100%;
            margin-top: 5px;
        }
            form {
                display: flex;
                justify-content: space-between;
                width: 100%;
                    input {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        @media only screen and (max-width: 768px) {
                            height: 55px;
                        }
                    }
                    button {
                        width: 70px;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        background-color: #fff;
                    }
            }
    }
    .shop-btn {
        a {
            padding: 0;
            width: 155px;
            height: 55px;
            margin-right: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media #{$sm-layout} {
                margin-right: 5px;
            }
            // &:last-child {
            //     margin-right: 0;
            // }
        }
    }
    .slick-slide.slick-current {
        .subtitle {
            animation: 800ms ease-in-out 0s normal none 1 running customOne;
        }
        .title {
            animation: 1000ms ease-in-out 0s normal none 1 running customOne;
        }
        .shop-btn {
            animation: 1200ms ease-in-out 0s normal none 1 running customOne;
        }
        .item-rating {
            animation: 1200ms ease-in-out 0s normal none 1 running customOne;
        }
        img {
            animation: 1200ms ease-in-out 0s normal none 1 running customOne;
        }
    }
}

.main-slider-large-thumb {
    width: 70vw;
    @media only screen and (max-width: 991px) {
        width: auto;
    }
    .slick-track {
        display: flex;
        align-items: flex-end;

    }
    .single-slide {
        position: relative;
        img {
            display: inline-block;
        }
        .product-price {
            height: 100px;
            width: 100px;
            background-color: var(--color-white);
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            position: absolute;
            top: 50px;
            left: 150px;
            visibility: hidden;
            opacity: 0;
            transform: scale(0);
            transition-delay: 0.5s;
            transition: all .5s ease-in-out;
            @media only screen and (min-width: 1600px) and (max-width: 1920px) {
                top: 100px;
                left: 200px;
            }
            span {
                &.text {
                    color: var(--color-body);
                }
                &.price-amount {
                    font-size: 18px;
                    font-weight: 700;
                    color: var(--color-primary);
                }
            }

        }
        &.slick-current {
            .product-price {
                visibility: visible;
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    .single-slide.slick-active:not(.slick-current) {
        img {
            width: 160px;
        }
    }
    .axil-slick-dots {
        .slick-dots {
            text-align: left;
            bottom: -70px;
            @media #{$large-mobile} {
                text-align: center;
                bottom: -55px;
            }
            li {
                button {
                    background-color: var(--color-white);
                    opacity: .5;
                }
                &.slick-active {
                    button {
                        background-color: var(--color-secondary);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.main-slider-style-1 {
    background-color: #f9f3f0;
    padding: 90px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-height: 550px;
    @media #{$smlg-device} {
        min-height: 500px;
    }
    @media only screen and (max-width: 991px) {
        padding: 40px 0 80px;
        min-height: 450px;
    }
    @media #{$sm-layout} {
        padding: 30px 0 60px;
        min-height: 100%;
    }
    .main-slider-content {
        margin: -30px;
        @media #{$large-mobile} {
            text-align: center;
        }
        @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
            align-items: center;
            max-height: initial;
        }
        .subtitle {
            @media #{$large-mobile} {
               justify-content: center;
            }
        }
        .title {
            @media #{$smlg-device} {
                font-size: 56px;
            }
            @media #{$md-layout} {
                font-size: 44px;
                margin-bottom: 40px;
            }
            @media #{$sm-layout} {
                font-size: 34px;
                margin-bottom: 30px;
            }

        }
        .item-rating {
            @media #{$smlg-device} {
                justify-content: flex-start;
            }
            @media #{$large-mobile} {
                justify-content: center;
            }
            .content {
                flex: initial;
            }
        }
        .single-slide {
            padding: 30px;
        }
    }
    .main-slider-large-thumb {
        @media #{$large-mobile} {
            text-align: center;
            padding: 40px 40px 0;
        }
        .single-slide {
            .product-price {
                @media #{$sm-layout} {
                    height: 80px;
                    width: 80px;
                    top: 0;
                    left: 0;
                    span {
                        font-size: 15px;
                        &.price-amount {
                            font-size: 15px;
                        }
                    }
                }    
                @media #{$large-mobile} {
                    left: 50px;
                }    
            }
        }
    }
    .shape-group {
        li {
            position: absolute;
            z-index: -1;
            @extend %liststyle;
            &.shape-1 {
                bottom: -100px;
                right: 33%;
                @media #{$laptop-device} {
                    right: 27%;
                }
                @media #{$smlg-device} {
                    right: 20%;
                }
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }
            &.shape-2 {
                bottom: -65px;
                right: 2%;
            }
        }
    }
}

.main-slider-style-2 {
    .slider-offset-left {
        margin-left: 290px;
        margin-top: 40px;
        margin-bottom: 40px;
        @media #{$smlg-device} {
            margin-left: 0;
        }
    }
    .slider-box-wrap {
        background-color: #f7f7f7;
        border-radius: 6px;
        padding: 48px 50px;
        @media #{$large-mobile} {
            padding: 40px 30px;

        }
        .single-slide {
            display: flex;
            align-items: center;
        }
        .axil-slick-dots {
            .slick-dots {
                bottom: -25px;
                @media #{$small-mobile} {
                    bottom: -30px;
                }
                li {
                    button {
                        background-color: var(--color-white);
                        opacity: 1;
                    }
                    &.slick-active {
                        button {
                            background-color: var(--color-secondary);
                            opacity: 1;
                        }
                    }
                }
            }
        }

    }
    .main-slider-content {
        flex: 1;
        z-index: 2;
        position: relative;
        @media #{$sm-layout} {
            flex: 2;
        }
        .title {
            font-size: 50px;
            margin-bottom: 45px;
            @media #{$smlg-device} {
                font-size: 40px;
            }
            @media #{$sm-layout} {
                font-size: 38px;
                margin-bottom: 30px;
            }
            @media #{$large-mobile} {
                font-size: 24px;
                margin-bottom: 20px;
            }
        }
        .axil-btn {
            padding: 0;
            position: relative;
            i {
                margin: 0 0 0 16px;
                color: var(--color-heading);
            }
            &:after {
                content: "";
                height: 1px;
                width: 100%;
                background-color: var(--color-heading);
                position: absolute;
                bottom: 0;
                left: 0;
            }
            &:hover {
                color: var(--color-primary);
                i {
                    color: var(--color-primary);
                    margin: 0 0 0 10px;
                }
                &:after {
                    background-color: var(--color-primary);
                }
            }
        }
    }
    .main-slider-thumb {
        position: relative;
        margin-left: 30px;
        z-index: 1;
        flex: 1;
        text-align: right;
        @media #{$small-mobile} {
            margin-left: 10px;
        }
        &::after {
            content: "";
            height: 288px;
            width: 288px;
            background-color: var(--color-white);
            border-radius: 50%;
            position: absolute;
            top: -5px;
            left: -100px;
            z-index: -1;
            @media #{$sm-layout} {
                height: 150px;
                width: 150px;
                left: 0;
                top: 0;
            }
            @media #{$large-mobile} {
                display: none;
            }
        }
        img {
            display: inline-block;
            max-height: 292px;
        }

    }
    .slider-product-box {
        background-color: #f7f7f7;
        border-radius: 6px;
        text-align: center;
        padding: 45px 20px;
        overflow: hidden;
        @media only screen and (max-width: 991px) {
            margin-top: 30px;
        }
        .product-thumb {
            margin-bottom: 35px;
            position: relative;
            z-index: 1;
            &::after {
                content: "";
                height: 160px;
                width: 160px;
                background-color: var(--color-white);
                border-radius: 50%;
                position: absolute;
                top: 20px;
                right: -10px;
                z-index: -1;
                @media only screen and (max-width: 991px) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        .title {
            font-size: 16px;
            color: var(--color-body);
            margin-bottom: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .price {
            font-size: 20px;
            font-weight: 700;
            color: var(--color-heading);
        }
    }
}

.main-slider-style-3 {
    background-color: #f3f7f6;
    padding: 110px 0;
    //background-image: url('../../assets/images/bg/bg-image-4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$md-layout} {
        padding: 80px 0;
    }
    @media #{$sm-layout} {
        padding: 40px 0 70px;
    }
    .main-slider-content {
        padding-right: 80px;
        @media only screen and (max-width: 991px) {
            padding-right: 0;
            margin-bottom: 50px;
        }
        @media #{$sm-layout} {
            margin-bottom: 25px;
        }
        .title {
            margin-bottom: 40px;
            font-size: 55px;
            line-height: 1.1;
            @media #{$laptop-device} {
                font-size: 60px;
            }
            @media #{$smlg-device} {
                font-size: 50px;
            }
            @media #{$md-layout} {
                font-size: 40px;
            }
            @media #{$sm-layout} {
                font-size: 34px;
            }

        }
    }
    .main-slider-large-thumb {
        width: 100%;
        position: relative;
        z-index: 1;
        .single-slide {
            text-align: center;
            .axil-product {
                transform: scale(.8);
                transition: .3s;
                margin: 45px -26px;
                position: relative;
                @media #{$smlg-device} {
                    margin: 45px -19px;
                }
                @media #{$large-mobile} {
                    margin: 20px 0;
                }

            }
            &.slick-active {
                &.slick-center {
                    z-index: 1;
                    .axil-product {
                        transform: scale(1.1);
                        box-shadow: 0px 10px 80px -87px rgba(0, 0, 0, .5);
                        @media #{$large-mobile} {
                            transform: scale(1);
                        }
                        &:before {
                            content: "";
                            width: 100%;
                            height: 50px;
                            background: #000000;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            filter: blur(100px);
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
        
        .single-slide.slick-active:not(.slick-current) {
            img {
                width: 100%;
            }
        }
        .axil-slick-dots {
            .slick-dots {
                text-align: center;
                bottom: -30px;
                li {
                    height: 10px;
                    width: 10px;
                    margin: 0 8px;
                    button {
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        background-color: transparent;
                        box-shadow: inset 0 0 0 5px rgba(0, 0, 0, .5);
                    }
                    &.slick-active {
                        button {
                            transform: scale(2);
                            box-shadow: inset 0 0 0 1px var(--color-primary);
                           
                        }
                    }
                }
            }
        }
    }
}

.main-slider-style-4 {
    background-color: #F9F3EF;
    padding-top: 50px;
    overflow: hidden;
    min-height: 600px;
    display: flex;
    align-items: flex-end;
    @media (max-width: 1399px) {
        min-height: 100%;
    }
    @media (max-width: 767px) {
        text-align: center;
    }
    .main-slider-content {
        z-index: 2;
        position: relative;
        padding: 10px 0;
        .title {
            line-height: 1.1;
            margin-bottom: 40px;
            animation: 600ms ease-in-out 0s normal none 1 running customOne;
        }
        .shop-btn {
            animation: 800ms ease-in-out 0s normal none 1 running customOne;
            a {
                @media (max-width: 991px) {
                    padding: 12px 20px;
                }
            }
        }
    }
    .slide-thumb-area {
        position: relative;
        margin-right: -100px;
        margin-left: -100px;
        z-index: 1;
        @media (max-width: 991px) {
            margin-right: -30px;
        }
        @media (max-width:767px) {
            margin-right: 0;
            padding: 20px 0 0;
            margin-left: 0;
        }

        .main-thumb {
            animation: 1200ms ease-in-out 0s normal none 1 running customTwo;
        }
        .shape-group {
            margin: 0;
            list-style: none;
            padding: 0;
            li {
                position: absolute;
                margin: 0;
                z-index: -1;
                transition: all 0.5s ease-in-out;
                visibility: hidden;
                opacity: 0;
                &.shape-1 {
                    bottom: 0;
                    right: -30px;
                    display: none;
                    svg {
                        path {
                            stroke-dasharray: 1190;
                            stroke-dashoffset: 1180;
                            transition: all 3s ease-in-out;
                        }
                    }
                }
                &.shape-2  {
                    top: -50px;
                    right: 50px;
                    @media (max-width: 767px) {
                        top: -250px;
                    }
                    svg {
                        path {
                            stroke-dasharray: 1190;
                            stroke-dashoffset: 1180;
                            transition: all 3s ease-in-out;
                        }
                    }
                }
            }
        }
        .banner-product {
            position: absolute;
            left: 80px;
            top: 220px;
            // visibility: hidden;
            // opacity: 0;
            transition: 0.3s;
            @media (max-width:767px) {
                display: none;
            }
            &:hover {
                .product-details {
                    visibility: visible;
                    opacity: 1;
                    margin-bottom: 15px;
                }
            }
            .plus-icon {
                border: 1px solid var(--color-dark);
                font-size: 12px;
                color: var(--color-dark);
                height: 29px;
                width: 29px;
                border-radius: 50%;
                line-height: 29px;
                text-align: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background-color: #F4E7DE;
                }

            }
            .product-details {
                min-width: 250px;
                width: 100%;
                position: absolute;
                background-color: var(--color-white);
                box-shadow: 0px 54px 94px rgba(172, 128, 117, 0.2);
                border-radius: 8px;
                bottom: 100%;
                left: -46px;
                padding: 15px 20px;
                margin-bottom: 30px;
                visibility: hidden;
                opacity: 0;
                transition: 0.3s;
                .title {
                    margin-bottom: 0;
                    font-size: 18px;
                    a {
                        transition: all 0.3s ease-in-out;
                    }
                }
                .price {
                    color: var(--color-secondary);
                    font-size: 22px;
                    font-weight: 700;
                }
                .product-rating {
                    margin-bottom: 5px;
                    .icon {
                        font-size: 16px;
                        color: #FACC15;
                    }
                    .rating-number {
                        font-size: 14px;
                        font-weight: 500;
                        color: var(--color-body);
                        margin-left: 5px;
                    }
                }
                &:after {
                    content: "";
                    width: 0; 
                    height: 0; 
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-top: 12px solid var(--color-white);
                    position: absolute;
                    bottom: -12px;
                    left: 52px;
                }
            }
        }
        &:hover {
            .banner-product {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    &.animation-init {
        .slide-thumb-area {
            .shape-group {
                li {
                    visibility: visible;
                    opacity: 1;
                    &.shape-1  {
                        svg {
                            path {
                                stroke-dashoffset: 0;
                                stroke-dasharray: 2000;
                            }
                        }
                    }
                    &.shape-2  {
                        svg {
                            path {
                                stroke-dashoffset: 0;
                                stroke-dasharray: 1300;
                            }
                        }
                    }
                }
            }
        }
    }
}

.main-slider-style-5 {
    
    .slider-box-wrap {
        background-color: #f7f7f7;
        border-radius: 6px;
        padding: 90px 100px;
        position: relative;
        z-index: 1;
        @media #{$smlg-device} {
            padding: 80px;
        }
        @media #{$md-layout} {
            padding: 60px;
        }
        @media #{$sm-layout} {
            padding: 50px 50px 60px;
        }
        @media #{$small-mobile} {
            padding: 40px 40px 60px;
        }
        &::after {
            content: "";
            height: 260px;
            width: 260px;
            background-color: var(--color-white);
            border-radius: 50%;
            position: absolute;
            top: 80px;
            left: 215px;
            z-index: -1;
            @media #{$md-layout} {
               left: 0;
               top: 0;
               transform: scale(.6);
            }
            @media #{$sm-layout} {
                display: none;
            }
        }
        .slider-activation-two {
            margin: -30px;
        }
        .single-slide {
            display: flex;
            align-items: center;
            padding: 30px;
            @media #{$large-mobile} {
                display: block;
            }
        }
        .axil-slick-dots {
            .slick-dots {
                bottom: -15px;
            }
        }
    }
    .main-slider-content {
        flex: 1;
        .title {
            width: 70%;
            @media only screen and (max-width: 1399px) {
                width: 90%;
            }
            @media #{$smlg-device} {
                width: 100%;
            }
            @media #{$sm-layout} {
                margin-bottom: 35px;
            }
        }
    }
    .main-slider-thumb {
        margin-left: 30px;
        flex: 1;
        text-align: right;
        img {
            display: inline-block;
            min-height: 460px;
            max-height: 460px;
            object-fit: contain;
            @media #{$smlg-device} {
                min-height: 300px;
                max-height: 300px;
            }
            @media #{$large-mobile} {
                min-height: 165px;
                max-height: 165px;
            }

        }
        @media #{$large-mobile} {
            text-align: center;
            margin-left: 0;
            margin-top: 45px;
        }
    }
}

.about-main-slider-area.main-slider-style-7 {
    height: 105px;
    @media #{$large-mobile} {
        height: 50px;
    }
}

.main-slider-style-7 {
    padding: 0;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (orientation: landscape) {
        display: none;
    }
    @media #{$large-mobile} {
        display: none;
    }
    @media #{$small-mobile} {
        display: none;
        background-position: right;
    }
    &.main-banner {
        @media (orientation: landscape) {
            display: block;
        }
        @media #{$large-mobile} {
            display: block;
        }
        @media #{$small-mobile} {
            display: block;
            background-position: right;
        }
    }
    .main-slider-content {
        z-index: 2;
        position: relative;
        padding: 1vh 0;
        min-height: 75px;
        max-height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
            align-items: center;
            max-height: initial;
        }
        .subtitle {
            animation: 800ms ease-in-out 0s normal none 1 running customOne;
        }
        .title {
            color: var(--color-white);
            text-transform: uppercase;
            width: auto;
            font-size: 20px;
            margin: 0;
            animation: 600ms ease-in-out 0s normal none 1 running customOne;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            @media #{$lg-layout} {
                font-size: 24px;
                max-width: 400px;
            }
            @media #{$md-layout} {
                font-size: 24px;
            }
        }
        p {
            font-size: 20px;
            animation: 1200ms ease-in-out 0s normal none 1 running customOne;
            @media #{$sm-layout} {
                font-size: 16px;
            }
        }
        .shop-btn {
            animation: 1400ms ease-in-out 0s normal none 1 running customOne;
            @media #{$sm-layout} {
                display: none;
            }
        }
    }
    .main-banner-image-container {
        position: relative;
        height: 25vh;
        @media (min-width: 992px) and (max-height: 700px) {
            height: 35vh;
        }
        .main-banner-image {
            position: absolute;
            top: -3vh;
            right: 5%;
            width: 35vh;
        }
    }
}

.main-slider-style-8 {
    .slider-offset-left {
        margin-bottom: 0;
    }
    .slider-box-wrap {
        // background-color: #ffefe9;
        padding: 48px 70px;
        @media only screen and (max-width: 575px) {
            padding: 45px 30px;
            
        }
    }
    .main-slider-thumb {
        &:after {
            left: -50px;
        }
    }
}


.slick-slide.slick-current {
    .main-slider-content {
        .subtitle {
            animation: 800ms ease-in-out 0s normal none 1 running customOne;
        }
        .title {
            animation: 1000ms ease-in-out 0s normal none 1 running customOne;
        }
        .shop-btn {
            animation: 1200ms ease-in-out 0s normal none 1 running customOne;
        }
    }
    .main-slider-thumb {
        img {
            animation: 1200ms ease-in-out 0s normal none 1 running customTwo;
        }
    }
}


// Animation
@keyframes customOne {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes customTwo {
    from {
        opacity: 0;
        transform: translate3d(20%, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.animationOne {
    animation: 1200ms customOne;
}

.animationTwo {
    animation: 1200ms customTwo;
}