/* -----------------------
Pagination 
--------------------------*/
.post-pagination {
  margin-top: 80px;
  width: 100%;
  @media #{$lg-layout} {
      margin-top: 40px;
  }
  @media #{$md-layout} {
      margin-top: 30px;
  }
  @media #{$sm-layout} {
      margin-top: 30px;
  }
  nav {
      &.pagination {
          display: block;
          .screen-reader-text {
              display: none;
          }
          ul {
              position: relative;
              display: flex;
              list-style: none;
              flex-wrap: wrap;
              align-items: center;
              margin: -3px;
              padding: 0;
              li {
                  margin: 3px;
                  span {
                      line-height: 42px;
                      min-width: 42px;
                      text-align: center;
                      color: var(--color-heading);
                      transition: all 0.5s;
                      display: block;
                      padding: 0 15px;
                      transition: all 0.5s;
                      border: 1px solid var(--color-border-light);
                      border-radius: var( --radius-small);
                      &.current {
                          background: var(--color-primary);
                          color: #ffffff;
                          border-color: var(--color-primary);
                      }
                  }
                  a {
                      line-height: 42px;
                      min-width: 42px;
                      text-align: center;
                      color: var(--color-heading);
                      transition: all 0.5s;
                      display: block;
                      padding: 0 15px;
                      transition: all 0.5s;
                      border: 1px solid var(--color-border-light);
                      border-radius: var( --radius-small);
                      &:hover {
                          background: var(--color-primary);
                          color: #ffffff;
                          border-color: var(--color-primary);
                      }
                  }
              }
          }
      }
  }
}