.subscribe-block {
  padding: 93px 15px;
  max-width: 1592px;
  width: 100%;
  border-radius: 8px;
  background-color: #F8F9F9;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .content-wrap {
    max-width: 810px;
    width: 100%;
  }

  h3, p {
    margin-bottom: 35px;
    color: #fff;
  }

  h3 {
    font-size: 48px;
  }

  p {
    font-size: 24px;
    font-weight: 700;

  }
}