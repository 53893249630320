/*-------------------------
About Us  
--------------------------*/
.axil-about-area {
	.about-thumbnail {
		@media only screen and (max-width: 991px) {
			margin-bottom: 50px;
			text-align: center;
		}
		@media only screen and (max-width: 767px) {
			margin-bottom: 40px;
		}
		img {
			border-radius: 10px;
			width: 100%;
		}
	}
	.about-content {
		.title {
			margin-bottom: 24px;
			line-height: 1.2;
			@media #{$smlg-device} {
				font-size: 36px;
			}
			@media #{$sm-layout} {
				font-size: 30px;
			}
		}
		.text-heading {
			font-size: 20px;
			margin-bottom: 22px;
			display: block;
		}
		p {
			font-size: var(--font-size-b2);
			margin-bottom: 34px;
		}
		&.content-right {
			padding-left: 50px;
			@media #{$smlg-device} {
				padding-left: 0;
			}
		}
		&.content-left {
			padding-right: 60px;
			@media #{$smlg-device} {
				padding-right: 0;
			}
		}
	}
	&.about-style-2 {
		padding-top: 80px;
		@media only screen and (max-width: 767px) {
			padding-top: 60px;
		}
		.about-content {
			.subtitle {
				font-size: 14px;
				margin-bottom: 5px;
				display: block;
			}
			.title {
				font-size: 40px;
				@media #{$smlg-device} {
					font-size: 34px;
				}
				@media #{$sm-layout} {
					font-size: 30px;
				}
			}
			.axil-btn {
				border-color: #efefef;
				&:hover {
					border-color: var(--color-primary);
				}
			}
		}
	}
}

.about-info-area {
	position: relative;
	z-index: 1;
	&:after {
		content: "";
		height: 50%;
		width: 100%;
		background-color: #f6f6f6;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
	}
}

.about-info-box {
	box-shadow: 0 16px 32px 0 rgba(0, 0, 0, .04);
	padding: 40px 50px;
	border: 1px solid var(--color-white);
	border-radius: 5px;
	background-color: var(--color-white);
	transition: var(--transition);
	margin-bottom: 30px;
	@media #{$sm-layout} {
		padding: 30px;
	}
	.thumb {
		margin-bottom: 26px;
	}
	.content {
		.title {
			margin-bottom: 12px;
			font-weight: 700;
		}
		p {
			font-size: var(--font-size-b2);
		}
	}
	&:hover {
		border-color: var(--color-primary);
	}
}

.about-style-3 {
	padding: 80px 0 0;
	margin-bottom: -20px;
	@media #{$sm-layout} {
		padding: 60px 0 0;
	}
	.section-title-wrapper {
		padding-right: 0;
		.title {
			margin-bottom: 10px;
		}
	}
}
.about-breadcrumb-area {
	padding: 100px 0;
	@media #{$large-mobile} {
		padding: 40px 0;
	}
}
.about-features {
	margin-bottom: 50px;
	@media #{$sm-layout} {
		margin-bottom: 30px;
	}
	.sl-number {
		font-size: 40px;
		font-weight: 700;
		color: var(--color-lightest);
		margin-bottom: 10px;
	}
	.title {
		margin-bottom: 10px;
	}
}

.about-gallery {
	.thumbnail {
		margin-bottom: 20px;
		img {
			border-radius: 6px;
			width: 100%;
		}
		&.thumbnail-1 {
			margin-top: 30px;
			@media only screen and (max-width: 991px) {
				margin-top: 0;
			}
			
		}
	}
}

.about-subtitle {
	margin: 0;
	width: 100%;
	text-align: left;
	font-size: 36px;
	font-weight: 700;
	text-transform: uppercase;
	@media #{$large-mobile} {
		text-align: center;
		font-size: 24px;
	}
}

.about-info-wrap {
	display: flex;
	flex-direction: column;
	padding: 0 15px 20px;

	@media (min-width: 992px) {
		padding: 0 0 40px;
	}

	h3 {
		text-align: left;
	}

	.wrap {
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (min-width: 992px) {
			flex-direction: row;
			align-items: stretch;
			justify-content: space-between;
		}
	}

	.about-info-item {
		max-width: 420px;
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;

		@media (min-width: 992px) {
			padding: 36px 41px 0;
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.icon-wrap {
			width: 56px;
			height: 56px;
			margin: 0 auto 20px;
			display: flex;

			@media (min-width: 992px) {
				margin: 0 auto 40px;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.content-wrap {
			display: flex;
			flex-direction: column;
			text-align: center;

			@media (min-width: 992px) {
				text-align: left;
			}
		}

		h4 {
			font-size: 18px;
			color: #053D7E;
			margin-bottom: 10px;
			flex: 1;

			@media (min-width: 992px) {
				margin-bottom: 24px;
				font-size: 24px;
			}
		}

		p {
			font-size: 14px;
			color: #19152B;
			margin-bottom: 0;

			@media (min-width: 992px) {
				font-size: 16px;
			}
		}

	}
}

.about-image-info {
	max-width: 1133px;
	width: 100%;
	padding: 20px 15px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 992px) {
		padding: 40px 0;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.image-wrap {
		max-width: 414px;
		width: 100%;
		margin-bottom: 24px;

		@media (min-width: 992px) {
			margin-bottom: 0;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.content-wrap {
		max-width: 516px;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		text-align: center;

		@media (min-width: 992px) {
			text-align: left;
			margin-left: 24px;
			margin-right: 0;
		}

		h4, p {
			color: #19152B;
			margin-bottom: 10px;

			@media (min-width: 992px) {
				margin-bottom: 24px;
			}
		}

		h4 {
			font-size: 25px;

			@media (min-width: 992px) {
				font-size: 36px;
			}
		}

		p {
			font-size: 14px;

			@media (min-width: 992px) {
				font-size: 16px;
			}
		}

		.axil-btn {
			padding: 15px 50px;
			margin: 0;

			@media (min-width: 992px) {
				padding: 22px 70px;
			}
		}
	}

	&.image-is-right {

		@media (min-width: 992px) {
			flex-direction: row-reverse;
		}

		.content-wrap {
			margin-left: 0;
			margin-right: 0;

			@media (min-width: 992px) {
				margin-right: 24px;
				margin-left: 0;
			}
		}
	}
}

.services-info {
	max-width: 1506px;
	width: 100%;
	margin: 0 auto -30px;
	padding: 20px 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	@media (min-width: 992px) {
		padding: 40px 15px;
	}

	.service-item {
		max-width: 339px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 10px;
		margin-bottom: 24px;

		@media (min-width: 992px) {
			margin-right: 40px;
			margin-bottom: 30px;
		}

		@media (min-width: 1507px) {
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.icon-wrap {
		min-width: 48px;
		width: 48px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #053D7E;
		border-radius: 50%;
		margin-right: 31px;

		@media (min-width: 992px) {
			min-width: 58px;
			width: 58px;
			height: 58px;
		}

		img {
			width: 26px;
			height: auto;

			@media (min-width: 992px) {
				width: 36px;
			}
		}
	}

	h5 {
		font-size: 18px;
		color: #053D7E;
		margin-bottom: 8px;
		line-height: normal;

		@media (min-width: 992px) {
			font-size: 24px;
		}
	}

	p {
		font-size: 14px;
		color: #19152B;
		line-height: normal;
		font-weight: 500;

		@media (min-width: 992px) {
			font-size: 16px;
		}
	}


}